import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line
import Card from '../../atoms/Card/Card';
import TileContainer from '../TileContainer/TileContainer';

import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading3, Heading5 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import InlineVideo from '../InlineVideo/InlineVideo';
import sanityImage from '../../../utils/sanityImage';

/**
 * Display a video tile that opens a video in a modal window when clicked.
 */
const VideoTile = ({
  description,
  heading,
  subheading,
  video,
  videoLength,
}) => {
  const videoRef = useRef(null);
  const [state, setState] = useState({ open: false });

  /**
   * when the card is clicked find the image wrapper for the current card
   * and click it to trigger it's click event that shows the video modal.
   */
  const handleImageClick = () => {
    if (!state.open) {
      videoRef.current.querySelectorAll('.image-wrapper')[0].click();
    }
  };

  // callback method that we use to update this
  // components state from within a child component.
  const updateState = newState => setState(newState);

  const cardCss = css`
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: ${shadows.largeHovered};
    }

    &:not(.open):hover {
      .play-button {
        transform: translateX(-50%) translateY(-50%) scale(1.2);
      }
    }
  `;

  // TODO: This logic is duplicated in LinkTile. Figure out how to make dry.
  const columnCss = css`
    @media (${breakpoints.largeTablet}) {
      &:last-of-type {
        width: 100%;
        margin-top: ${uc('40px')};
      }
    }

    @media (${breakpoints.mobile}) {
      &:last-of-type {
        margin-top: 0;
      }
    }
  `;

  /**
   * Hide the image that inlinevideo adds because we set it as the background
   * image on .image-wrapper
   *
   * @type {SerializedStyles}
   */
  const imageWrapperCss = css`
    position: relative;
    pointer-events: none;

    img {
      z-index: 1;
    }

    .image-wrapper {
      min-height: ${uc('242px')};
      background: transparent
        url(${sanityImage(video.thumbnail)
          .auto('format')
          .width(600)
          .fit('max')
          .url()})
        0 0 no-repeat;
      background-size: cover;

      img {
        display: none;
      }
    }
  `;

  const timeCss = css`
    position: absolute;
    right: ${uc('30px')};
    bottom: ${uc('20px')};
    z-index: 2;
    color: ${colors.white};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.proximaSoft};
    text-shadow: ${shadows.smallText};
  `;

  const cardContentCss = css`
    padding: ${uc('30px 30px 0')};
    background-color: ${colors.white};
  `;

  const headingCss = css`
    font-size: ${fontSizes.twenty};
    line-height: 1.34;
    letter-spacing: ${uc('0.3px')};
  `;

  const subheadingCss = css`
    margin: ${uc('0 0 20px')};
    font-weight: ${fontWeights.black};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.roboto};
    letter-spacing: ${uc('1.2px')};
    text-transform: uppercase;
  `;

  const descriptionCss = css`
    margin-bottom: ${uc('40px')};
  `;

  return (
    <TileContainer css={columnCss}>
      <Card
        css={cardCss}
        onClick={handleImageClick}
        className={state.open ? 'open' : 'closed'}
        data-cy="video-tile"
      >
        <div css={imageWrapperCss}>
          {videoLength && <span css={timeCss}>{videoLength}</span>}
          <div>
            {video && (
              <InlineVideo
                video={video.videoURL}
                image={video.thumbnail}
                videoRef={videoRef}
                updateState={updateState}
              />
            )}
          </div>
        </div>
        {(subheading || heading) && (
          <div css={cardContentCss}>
            {subheading && (
              <Heading5 color={colors.mediumGray} css={subheadingCss}>
                {subheading}
              </Heading5>
            )}
            {heading && (
              <Heading3 color={colors.primary} css={headingCss}>
                {heading}
              </Heading3>
            )}
            {description && <Text css={descriptionCss}>{description}</Text>}
          </div>
        )}
      </Card>
    </TileContainer>
  );
};

VideoTile.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  description: PropTypes.string.isRequired,
  video: PropTypes.shape({
    videoURL: PropTypes.shape({}).isRequired,
    thumbnail: PropTypes.shape({}).isRequired,
  }).isRequired,
  videoLength: PropTypes.string,
};

VideoTile.defaultProps = {
  videoLength: false,
  subheading: false,
};

export default VideoTile;
