// similar to page but with columns prop
import React from 'react';

export const renderTileSections = (
  tileProps,
  sectionSchemas,
  columns,
  useShortTiles
) => {
  try {
    return tileProps.map(sectionData => {
      const MyComponent = sectionSchemas[sectionData._type];
      return (
        <MyComponent
          key={`tiles-${sectionData._key}`}
          {...sectionData}
          columns={columns}
          short={useShortTiles}
        />
      );
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Section component type not found. Check the const sectionSchemaName on the page template. ',
      error
    );
  }
  return null;
};

export default {
  renderTileSections,
};
