import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading2 } from '../../atoms/Headings/Headings';
import videoTile from '../../molecules/VideoTile/VideoTile';
import linkTile from '../../molecules/LinkTile/LinkTile';
import fullImageTile from '../../molecules/FullImageTile/FullImageTile';
import personTile from '../../molecules/PersonTile/PersonTile';
import Section from '../../molecules/Section/Section';
import ResponsiveContentWrapper from '../ResponsiveContentWrapper/ResponsiveContentWrapper';
import {
  colors,
  lineHeights,
  unitConverter as uc,
  pageDimensions,
} from '../../../styles/base';
import { renderTileSections } from '../../templates/renderTileSections';

const TilesSection = ({
  columns,
  sectionHeading,
  sectionStyles,
  tiles,
  useShortTiles,
}) => {
  let columnsWidth = columns;

  // if person tile exists, set columns to 4
  if (tiles.filter(tile => tile._type === 'personTile')) {
    columnsWidth = 4;
  }

  const tileType = {
    fullImageTile,
    linkTile,
    personTile,
    videoTile,
  };
  const columnSpacing = columns > 1 ? '2%' : 0;
  const visibilityCss = css`
    position: relative;
    width: calc(100% / ${columnsWidth} - ${columnSpacing});
    max-width: ${uc('430px')};
    visibility: hidden;

    @media (max-width: ${pageDimensions.smallDesktopWidth}) {
      display: none;
    }
  `;

  const tileTotal = Object.keys(tiles).length;

  let leftOvers = tileTotal % columnsWidth;

  if (leftOvers < columnsWidth / 2) leftOvers = columnsWidth - leftOvers;

  const updatedTiles = renderTileSections(
    tiles,
    tileType,
    columnsWidth,
    useShortTiles
  );

  if (leftOvers > 0) {
    for (let i = leftOvers; i > 0; i -= 1) {
      updatedTiles.push(<div key={`leftOvers-${i}`} css={visibilityCss} />);
    }
  }

  const tilesSectionGridCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    > div {
      margin-bottom: ${uc('20px')};
    }
  `;

  const heading2Css = css`
    flex-basis: 100%;
    margin: 0 auto ${uc('60px')};
    color: ${colors.darkGray.two};
    line-height: ${lineHeights.copy};
    text-align: center;
  `;

  return (
    <Section sectionStyles={sectionStyles}>
      <ResponsiveContentWrapper>
        <div css={tilesSectionGridCss}>
          {sectionHeading && (
            <Heading2 css={heading2Css}>{sectionHeading}</Heading2>
          )}
          {updatedTiles}
        </div>
      </ResponsiveContentWrapper>
    </Section>
  );
};

TilesSection.propTypes = {
  columns: PropTypes.number,
  sectionHeading: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
  useShortTiles: PropTypes.bool,
};

TilesSection.defaultProps = {
  columns: 3,
  sectionHeading: '',
  sectionStyles: {},
  tiles: [],
  useShortTiles: false,
};

export default TilesSection;
