import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line
import Card from '../../atoms/Card/Card';
import TileContainer from '../TileContainer/TileContainer';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading3, Heading5 } from '../../atoms/Headings/Headings';
import sanityImage from '../../../utils/sanityImage';

/**
 * Display a link tile that opens a link when clicked.
 */
const LinkTile = ({
  heading,
  link,
  openInNewWindow,
  short,
  subheading,
  tileImage,
}) => {
  /**
   * Get the url from the link object provided by sanity.
   * Currently it can either be a route or an externalLink.
   *
   * @param linkObj
   * @return {boolean|string}
   */
  const getLinkUrl = linkObj => {
    let url = false;
    if (linkObj.link !== undefined && linkObj.link.length > 0) {
      const _link = linkObj.link[0];

      switch (_link._type) {
        case 'route':
          url = _link.slug.current;
          break;
        case 'externalLink':
          url = _link.externalLink;
          break;
        default:
          url = false;
      }
    }
    return url;
  };

  const target = openInNewWindow ? '_blank' : '_self';

  const cardCss = css`
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: ${shadows.largeHovered};
    }
  `;

  // TODO: This logic is duplicated in VideoTile. Figure out how to make dry.
  const columnCss = css`
    @media (${breakpoints.largeTablet}) {
      &:last-of-type {
        width: 100%;
        margin-top: ${uc('40px')};
      }
    }

    @media (${breakpoints.mobile}) {
      &:last-of-type {
        margin-top: 0;
      }
    }
  `;

  const imageWrapperCss = css`
    position: relative;

    .background {
      width: 100%;
      min-height: ${short ? uc('121px') : uc('242px')};
      background: transparent
        url(${sanityImage(tileImage)
          .auto('format')
          .width(800)
          .fit('max')
          .url()})
        0 0 no-repeat;
      background-size: cover;
    }
  `;

  const cardContentCss = css`
    padding: ${uc('30px 30px 0')};
    background-color: ${colors.white};
  `;

  const headingCss = css`
    margin-bottom: ${uc('30px')};
    font-size: ${fontSizes.twenty};
    line-height: 1.34;
    letter-spacing: ${uc('0.3px')};
  `;

  const subheadingCss = css`
    margin: ${uc('0 0 20px')};
    color: ${colors.lightGray.seven};
    font-weight: ${fontWeights.black};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.roboto};
    letter-spacing: ${uc('1.2px')};
    text-transform: uppercase;
  `;

  return (
    <TileContainer css={columnCss}>
      <a href={getLinkUrl(link)} alt={heading} target={target}>
        <Card css={cardCss}>
          <div css={imageWrapperCss}>
            <div className="background" />
          </div>
          {(subheading || heading) && (
            <div css={cardContentCss}>
              {subheading && (
                <Heading5 color={colors.mediumGray} css={subheadingCss}>
                  {subheading}
                </Heading5>
              )}
              {heading && (
                <Heading3 color={colors.primary} css={headingCss}>
                  {heading}
                </Heading3>
              )}
            </div>
          )}
        </Card>
      </a>
    </TileContainer>
  );
};

LinkTile.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.shape({}).isRequired,
  openInNewWindow: PropTypes.bool,
  short: PropTypes.bool,
  subheading: PropTypes.string,
  tileImage: PropTypes.shape({}).isRequired,
};

LinkTile.defaultProps = {
  openInNewWindow: false,
  short: false,
  subheading: null,
};

export default LinkTile;
