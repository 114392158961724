/**
 * <FullImageTile> Component
 */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { colors, durations, unitConverter as uc } from '../../../styles/base';
import Card from '../../atoms/Card/Card';
import sanityImage from '../../../utils/sanityImage';
import { Heading3 } from '../../atoms/Headings/Headings';
import Link from '../../atoms/Link/Link';
import Text from '../../atoms/Text/Text';
import TileContainer from '../TileContainer/TileContainer';

const FullImageTile = ({
  bgImage,
  blueOverlay,
  columns,
  heading,
  link,
  subheading,
}) => {
  // add blue to tile, removes blue on hover
  const blueOverlayCss = css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 71, 186, 0.5);
      transition: 0.3s;
    }

    &:active,
    &:focus,
    &:hover {
      &::after {
        top: 100%;
      }
    }
  `;

  const fullImageTileCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: ${uc('320px')};
    margin-bottom: ${uc('50px')};
    overflow: hidden;
    background-image: url(${sanityImage(bgImage)
      .auto('format')
      .width(450)
      .url()});
    background-position: center;
    background-size: cover;

    ${blueOverlay ? blueOverlayCss : ''}
  `;

  const execTextCss = css`
    position: absolute;
    bottom: ${uc('20px')};
    z-index: 1;
    display: inline-block;
    margin: 0 auto;
    padding: ${uc('0 5px')};
    text-align: center;
    text-shadow: ${uc('0 3px 3px')} rgba(0, 0, 0, 0.5);
    vertical-align: middle;
    transition: ${durations.medium};

    ${Heading3} {
      margin-bottom: 0;
    }
  `;

  return (
    <TileContainer columns={columns}>
      <Link to={link}>
        <Card css={fullImageTileCss}>
          <div css={execTextCss}>
            <Heading3 color={colors.white}>{heading}</Heading3>
            <Text color={colors.white}>{subheading}</Text>
          </div>
        </Card>
      </Link>
    </TileContainer>
  );
};

FullImageTile.propTypes = {
  bgImage: PropTypes.shape({}),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  link: PropTypes.shape({}),
  blueOverlay: PropTypes.bool,
  columns: PropTypes.number,
};

FullImageTile.defaultProps = {
  bgImage: {},
  heading: '',
  subheading: '',
  link: false,
  blueOverlay: false,
  columns: 3,
};

export default FullImageTile;
