/**
 * <PersonTile> Component
 */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';
import FullImageTile from '../FullImageTile/FullImageTile';

const PersonTile = ({ columns, person: { image, link, name, title } }) => (
  <FullImageTile
    heading={name}
    subheading={title}
    bgImage={image}
    link={link}
    blueOverlay
    columns={columns}
  />
);

PersonTile.propTypes = {
  person: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.number.isRequired,
};

export default PersonTile;
